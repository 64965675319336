import React from 'react'

function Testmonial() {
    return (
        <section className='py-5'>
            <div className="container">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <div className="my-5">
                            <h6 className='text-capitalize'>WHAT PARENT SAY</h6>
                        </div>
                    </div>

                </div>
                <div className="w-75 m-auto testmonial">
                    {/* <div className="container-fulied">
                                <div className="row align-items-center g-0">
                                    
                                </div>
                            </div> */}
                    <div className="testmonial-content d-flex sls-bg-first-color text-light rounded-5 gap-3">
                        <div className='w-40'>
                            <img src="images/Frame-70.jpg" alt="kids parent" className='w-100 rounded-3 kid-testmonial-imag' />
                        </div>

                        <div className='w-60'>
                            <div className='p-5 text-quaut'>
                                <q className='lead'>Great school and my child loves it .
                                    Special thanks to the teachers .
                                    One of the best Playschools in Alexandria .
                                    The curriculum is good and bring the best
                                    out of the child .
                                    The teaching methods are engaging and
                                    give the children the chance to be creative .</q>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Testmonial