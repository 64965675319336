import React from 'react'

function Enroll() {
  return (
    <section className='enroll'>
        <div className='overlay'>
            <div className='d-flex justify-content-center py-5 text-light text-center'>
                <div className='my-5'>
                    <h2 className='fs-1'>More than just a joyful place</h2>
                    <p className='fs-small'>
                    Enroll your child in the best Playschool in Alexandria. Discover the joy of learning at New SLS 
                    </p>
                    <a href='#' className='btn btn-dark btn-sls-second-color btn-sm rounded-5 px-4 py-3 fisrt-capitalize'>enroll your kid</a>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Enroll