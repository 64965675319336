import React  from 'react';
import Navbar from './components/Navbar/Navbar';
import Home from './components/Home/Home';
import Footer from './components/Footer/Footer';
import { Route, Routes } from 'react-router-dom';
import Error from './components/Error/Error';
import { HelmetProvider } from 'react-helmet-async';

function App() {
  const helmetContext = {};

  return <>
  <HelmetProvider context={helmetContext}>
  <Navbar></Navbar>
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='*' element={<Error />} />
    </Routes>
    <Footer />
  </HelmetProvider>
    
  </>
}

export default App;
