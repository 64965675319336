import React, { Component } from 'react'

export class About extends Component {
  render() {
    return (
      <section className='my-5' id='about'>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className='text-center'>
                <img src="./images/HAS_0125.JPG" alt="kids" className='w-75 rounded-3 shadow'/>
              </div>
            </div>
            <div className="col-md-6">
              <h6 className='text-capitalize'>about us</h6>
              <h2 className='sls-main-color'>We are here to help parents raise happy and healthy children</h2>
              <p className='lead'>
              All children begin PlaySchool with a variety of experiences it is 
              the privilege of our Early Year staff to build upon that prior 
              experience. We aim to work alongside our children’s parents to 
              ensure children have the best start to their school life and lay the 
              secure foundations to support their academic success in a happy 
              environment.
              </p>
              <a href="#more-about" className='btn btn-primary btn-sls-second-color btn-sm rounded-5 px-4 py-3 fisrt-capitalize'>know more about us</a>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default About