import React from 'react'
import Header from './../Header/Header';
import About from '../About/About';
import Care from '../Care/Care';
import Testmonial from '../Testmonial/Testmonial';
import Enroll from '../Enroll/Enroll';
import Seo from './../Seo/Seo';

function Home() {
  return <>
    <Seo title={'SLS Playschool'} />
    <Header />
    <About />
    <Care />
    <Testmonial />
    <Enroll />
  </>
  
}

export default Home