import React from 'react'

function Footer() {
  return (
    <footer className='sls-bg-footer-color pt-5'>
        <div className='container'>
            <div className="row py-3">
                <div className="col-sm-4">
                    <div className='text-start'>
                        <div>
                            <img src="./images/logo.png" alt="sls playschool" width={150} />
                        </div>
                        <ul className='list-unstyled mt-3 d-flex ms-4 gap-3'>
                            <li>
                                <a href="https://m.facebook.com/groups/1377901915597923/?ref=share&mibextid=K8Wfd2" target='_blank'>
                                    <i className="fa-brands fa-facebook text-dark fa-2x" aria-hidden="true"></i>
                                </a>
                            </li>

                            <li>
                                <a href="https://wa.me/message/VQZULA7SRVBGN1?src=qr" target='_blank'>
                                    <i className="fa-brands fa-whatsapp text-dark fa-2x" aria-hidden="true"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className='text-start'>
                        <div>
                            <h2 className='sls-main-color fs-4'>Quick link</h2>
                        </div>
                        <div>
                        <ul className='list-unstyled mt-3'>
                            <li>
                                <a href="#" className='text-decoration-none text-dark'>
                                    New SLS Playschool
                                </a>
                            </li>
                            <li>
                                <a href="#about" className='text-decoration-none text-dark'>
                                    About
                                </a>
                            </li>
                            <li>
                                <a href="https://app.sidigaber.org/" className='text-decoration-none text-dark'>
                                    Addmission
                                </a>
                            </li>
                            <li>
                                <a href="#" className='text-decoration-none text-dark'>
                                    {/* Contact */}
                                </a>
                            </li>
                        </ul>
                        </div>
                    </div>
                </div>

                <div className="col-sm-4">
                    <div className='text-start'>
                        <div>
                            <h2 className='sls-main-color fs-5'>Get in touch</h2>
                        </div>
                        <div>
                        <ul className='list-unstyled mt-3'>
                            <li>
                                <strong>Address</strong>: Abees (10) , Ring Road ,Alexandria , Egypt
                            </li>
                            <li>
                                <strong>Hours</strong>: 08:00-13:00
                            </li>
                            <li>
                                <strong>Phone</strong>:  01098451366-034308024
                            </li>
                            <li>
                                <strong>whatsApp</strong>:  01030314500
                            </li>
                            <li>
                                <strong>Email</strong>: <a href="mailto:sls.playschool.dep@gmail.com" className='sls-second-color text-decoration-none'>sls.playschool.dep@gmail.com</a>
                            </li>
                        </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="copy-right sls-bg-first-color d-flex text-light justify-content-center align-items-center">
            <p className='my-2'>Copyright &copy; {new Date().getUTCFullYear()} Best Play School in Alexandria. Powered by Best Play School in Alexandria.</p>
        </div>
    </footer>
  )
}

export default Footer