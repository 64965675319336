import React from 'react'
import { Helmet } from 'react-helmet-async'

function Seo(object) {
    let {title} = object;
    let type = 'page';
    let description = `All children begin PlaySchool with a variety of experiences it is 
    the privilege of our Early Year staff to build upon that prior 
    experience. We aim to work alongside our children’s parents to 
    ensure children have the best start to their school life and lay the 
    secure foundations to support their academic success in a happy 
    environment.`;
    let keywords = "SLS, Playschool, Playschool department, NewSLS, New sls";
    let name = "SLS PlaySchool"
  return (
    <Helmet>
        <title>{title}</title>
        <meta name="keywords" content={keywords} />
        <meta name="description" content={description} />

        <meta property="og:type" content={type} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />

        <meta name="twitter:creator" content={name} />
        <meta name="twitter:card" content={type} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
    </Helmet>
  )
}

export default Seo