import React from 'react'
import { Link } from 'react-router-dom'
import Seo from './../Seo/Seo';

export default function Error() {
  return <>
    <Seo title={'SLS Playschool | Page Not Found'}/>
    <div className='container'>
        <div className='my-3'>
            <div className='text-center'>
                <img src="/images/404.webp" alt="error"/>
            </div>
            <div className='text-center'>
                <Link className='btn btn-warning' to="/">Return back to home page</Link>
            </div>
        </div>
    </div>
  </>
}
