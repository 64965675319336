import React from 'react'

function Header() {
  return (
    <section className='overlay-section'>
        <div className="container">
        <div className="row align-items-center">
            <div className="col-sm-12 col-lg-6">
                <div className='my-5'>
                <h1 className='sls-main-color lh-base'>It's not just a Playschool <br /> It's <span className='sls-second-color'>the early year foundation stage</span></h1>
                <p className='lead'>
                    At New SLS Playschool Department we are committed to providing a 
                    high quality Early Year education. Which is a very important stage in a 
                    child’s life as it helps prepare for school ‘readiness’ as well as preparing 
                    them for their future learning and successes. Children’s early year 
                    experience should be happy, active, exciting, fun and secure; and 
                    support their development, care and learning needs. Every child 
                    deserves the best possible start in life and the support that enables 
                    them to fulfill their potential. Children develop quickly in the early years 
                    and a child’s experiences before the age of five will have a 
                    major impact on their future life chances which gives a secure and 
                    confident start.
                </p>
                <a href="#" className='btn btn-primary btn-sls-second-color btn-sm rounded-5 px-4 py-3 fisrt-capitalize'>enroll your kid</a>
                </div>
            </div>

            <div className="col-sm-12 col-lg-6">
                <div className='my-5'>
                    <div className='text-center image'>
                    <img src="./images/header.png" alt="sls kids"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className='d-none d-lg-flex position-absolute end-0 top-50 social'>
        <ul className='list-unstyled'>
            <li className='mb-3'>
                <a href="https://m.facebook.com/groups/1377901915597923/?ref=share&mibextid=K8Wfd2" target='_blank' className='text-light'>
                    <i className="fa-brands fa-facebook fa-2x"></i>
                </a>
            </li>
            <li className='mb-3'>
                <a href="https://wa.me/message/VQZULA7SRVBGN1?src=qr" target='_blank' className='text-light'>
                    <i className="fa-brands fa-whatsapp fa-2x"></i>
                </a>
            </li>
        </ul>
    </div>
    </section>
  )
}

export default Header