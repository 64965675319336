import React from 'react'

function Care() {
    return (
        <section className='py-5 care-section'>
            <div className="container">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <div className="my-5">
                            <h6 className='text-uppercase' id='more-about'>Playschool OPTIONS</h6>
                            <h2 className='sls-main-color'>Children will learn skills, acquire
                                new knowledge and demonstrate
                                their understanding</h2>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="row gy-5">
                            <div className='col-md-4'>
                                <div className='border border-1 border-dark rounded-3 bg-light p-2 text-center'>
                                    <div>
                                        <img src="/images/IMG_6250.CR2" alt="kid" className='w-100 rounded-4 image-translate shadow-lg' />
                                    </div>
                                    {/* <h3 className='fs-4'>Lorem, ipsum dolor.</h3> */}
                                    <p className='fs-6'>
                                        Our curriculum will
                                        support children and
                                        their healthy
                                        development and
                                        provide the
                                        foundations that
                                        children need to make
                                        the most of
                                        their abilities and
                                        talents as they grow up.

                                    </p>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='border border-1 border-dark rounded-2 bg-light p-2 text-center'>
                                    <div>
                                        <img src="/images/IMG_6191.JPG" alt="kid" className='shadow-lg image-translate w-100 rounded-4' />
                                    </div>
                                    {/* <h3 className='fs-4'>Lorem, ipsum dolor.</h3> */}
                                        <p className='fs-6'>
                                            Children should mostly
                                            develop the
                                            3 prime areas first.
                                            These are:
                                        </p>
                                        <ul className='list-unstyled'>
                                                <li>-Communication and
                                                    language
                                                </li>
                                                <li>-Physical development;
                                                </li>
                                                <li>- Personal, social and
                                                    emotional
                                                    development.</li>
                                        </ul>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='border border-1 border-dark rounded-2 bg-light p-2 text-center'>
                                    <div>
                                        <img src="/images/IMG_6147.JPG" alt="kid" className='image-translate shadow-lg w-100 rounded-4' />
                                    </div>
                                    {/* <h3 className='fs-4'>Lorem, ipsum dolor.</h3> */}
                                    <p className='fs-6'>
                                        The prime areas will
                                        help them to develop
                                        skills in
                                    </p>
                                    <ul className='list-unstyled'>
                                            <li><strong>4 specific areas.</strong></li>
                                            <li>- Literacy
                                            </li>
                                            <li>- Mathematics
                                            </li>
                                            <li>- Understanding the
                                                world
                                            </li>
                                            <li>
                                                - Expressive arts and
                                                design.
                                            </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 text-center">
                        <div className="my-5">
                            {/* <h6 className='text-uppercase'>Play School</h6> */}
                            <h2 className='sls-main-color'>Discover Excellence , Empowerment & Enjoyment playschool services for all children!</h2>
                            {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia saepe, distinctio blanditiis fuga fugiat nemo ?</p> */}
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="row my-5 gy-2">
                            <div className="col-md-5">
                                <div>
                                    <h6>WHY US</h6>
                                    <h2>The Best Early Learning Experience</h2>
                                    <p className='lead'>
                                        Children in the Palyschool
                                        department learn by playing ,
                                        and exploring, being active, also
                                        through creative and critical
                                        thinking which takes place both
                                        indoors and outside. It is very
                                        important that they develop
                                        social skills, such as turn-taking,
                                        sharing and independence,
                                        which help them greatly in the
                                        next stages of their
                                        learning. The guiding principles
                                        that shape our practice in the
                                        Early Year are that children are
                                        ready, able and eager to learn!
                                    </p>
                                </div>
                                {/* <ul className='list-unstyled'>
                                    <li>
                                        <i className="fa fa-check me-2 sls-second-color" aria-hidden="true"></i>
                                        Lorem ipsum dolor sit amet consectetur.
                                    </li>
                                    <li>
                                        <i className="fa fa-check me-2 sls-second-color" aria-hidden="true"></i>
                                        Lorem ipsum dolor sit amet consectetur.
                                    </li>
                                    <li>
                                        <i className="fa fa-check me-2 sls-second-color" aria-hidden="true"></i>
                                        Lorem ipsum dolor sit amet consectetur.
                                    </li>
                                </ul> */}
                                <a href="#" className='btn btn-primary btn-sls-second-color btn-sm rounded-5 px-4 py-3 fisrt-capitalize'>Know more</a>
                            </div>
                            <div className="offset-md-1 col-md-6">
                                <div className="row gy-5">
                                    <div className="col-md-6">
                                        <div>
                                            <div className='bg-light mb-3 border rounded-3 border-1 p-3 border-dark'>
                                                <h6><i className="fa fa-heart sls-second-color" aria-hidden="true"></i></h6>
                                                <h6>Expressive art and
                                                    design</h6>
                                                <p className='fs-075'>
                                                    Children will have daily
                                                    opportunities to engage in
                                                    imaginative play through
                                                    drama, puppets,
                                                    collage materials (various
                                                    papers and cards) to use
                                                    their own imaginations to
                                                    create whatever they
                                                    wish. Songs will be sung
                                                    throughout the day as well
                                                    as during weekly music
                                                    and singing sessions.
                                                </p>
                                            </div>
                                            <div className='bg-light border rounded-3 border-1 p-3 border-dark'>
                                                <h6><i className="fa-solid fa-icons sls-second-color" aria-hidden="true"></i></h6>
                                                <h6>Physical Development</h6>
                                                <p className='fs-075'>
                                                    Children through the PE
                                                    sessions will be learning
                                                    variety of skills including
                                                    team work, control,
                                                    coordination and will
                                                    participate in using tools
                                                    throughout the learning
                                                    environment.

                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className='translatex-50'>
                                            <div className='bg-light mb-3 border rounded-3 border-1 p-3 border-dark'>
                                                <h6><i className="fa fa-face-smile sls-second-color" aria-hidden="true"></i></h6>
                                                <h6>emotional
                                                    development</h6>
                                                <p className='fs-075'>
                                                    The children are settling
                                                    into a new environment
                                                    and are establishing new
                                                    relationships. They will be
                                                    developing their sharing
                                                    and listening skills and
                                                    talking about feelings. This
                                                    will be done throughout the
                                                    day and during special
                                                    circle time sessions. They
                                                    will also learn about school
                                                    rules and how they are
                                                    expected to behave.
                                                </p>
                                            </div>
                                            <div className='bg-light border rounded-3 border-1 p-3 border-dark'>
                                                <h6><i className="fa fa-drafting-compass sls-second-color" aria-hidden="true"></i></h6>
                                                <h6>Understanding the
                                                    World</h6>
                                                <p className='fs-075'>Children will be learning
                                                    to recognize how the
                                                    world works, also how to
                                                    think critically, which
                                                    gives them the
                                                    confidence they need to
                                                    ask questions and find
                                                    the answers.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Care